import PublicLayout from "../components/PublicLayout";
import { NavigationErrorDetails } from "../feature/subscription/components/Support";

const Custom404 = () => (
  <PublicLayout pageIdentifier="404">
    <div className="flex items-center justify-center">
      <div className="flex min-h-[80vh] w-full flex-col rounded-lg bg-white p-4 shadow-lg sm:w-3/4">
        <article className="prose mx-auto mt-10">
          <h1 className="flex justify-center">Page Not Found</h1>
          <NavigationErrorDetails />
        </article>
      </div>
    </div>
  </PublicLayout>
);

export default Custom404;
