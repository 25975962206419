import Link from "next/link";
import { useRouter } from "next/router";
import Button from "components/fundamentals/Button";

export interface SupportPageProps {
  readonly knownUser?: boolean;
  readonly showDiscover?: boolean;
  readonly salesEmailAddress?: string;
}

export const NavigationErrorDetails = (props: SupportPageProps) => {
  const router = useRouter();
  return (
    <>
      <div className="flex flex-col gap-6">
        <div>
          For Clients:
          <ul>
            <li>
              If you are looking for access to your Compound Dashboard, please log in at{" "}
              <a href="https://app.compoundplanning.com/" rel="noopener noreferrer">
                https://app.compoundplanning.com/
              </a>
              .
            </li>
            <li>
              Or contact our Support team by sending an email to:{" "}
              <a href="mailto:support@compoundplanning.com">support@compoundplanning.com</a>.
            </li>
          </ul>
        </div>

        <div>
          For Advisor Support:
          <ul className="ml-4">
            <li>
              If you believe you should have access, then you may have logged in with the incorrect
              email. You can try to
              <span className="mx-3">
                <Button onClick={() => router.push("/api/auth/logout")}>Sign Out</Button>
              </span>
              and login with a different email.
            </li>

            {props.knownUser && (
              <li>
                Or contact our Support team by sending an email to:{" "}
                <a href="mailto:support@compoundplanning.com">support@compoundplanning.com</a>.
              </li>
            )}
          </ul>
        </div>

        <div>
          For Sales:
          <ul>
            <li>
              Contact our Sales team by sending an email to:{" "}
              <a href={`mailto:${props.salesEmailAddress}`}>sales@compoundplanning.com</a>.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export const SupportPage = (props: SupportPageProps) => (
  <div className="flex items-center justify-center">
    <div className="flex min-h-[80vh] w-full flex-col rounded-lg bg-white p-4 shadow-lg sm:w-3/4">
      <article className="prose mx-auto mt-10">
        <h1 className="flex justify-center">Welcome to Compound Planning</h1>
        <NavigationErrorDetails {...props} />
      </article>
      {props.showDiscover && (
        <div className="my-6 flex justify-center">
          <Link href={"/discover"}>
            <Button size="large" isPrimary>
              Discover Alts
            </Button>
          </Link>
        </div>
      )}
    </div>
  </div>
);
